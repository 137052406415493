// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-tech-page-js": () => import("/home/runner/work/rainer-codes/rainer-codes/src/templates/tech-page.js" /* webpackChunkName: "component---src-templates-tech-page-js" */),
  "component---src-templates-experience-page-js": () => import("/home/runner/work/rainer-codes/rainer-codes/src/templates/experience-page.js" /* webpackChunkName: "component---src-templates-experience-page-js" */),
  "component---src-pages-404-js": () => import("/home/runner/work/rainer-codes/rainer-codes/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/rainer-codes/rainer-codes/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/runner/work/rainer-codes/rainer-codes/.cache/data.json")

