import React from 'react'
import { Link } from 'gatsby'

const Tech = ({ children }) => (
  <Link to={'/tech/' + children.toLowerCase()}>
    <span className="font-bold text-black">{children}</span>
  </Link>
)

export default Tech
