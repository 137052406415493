import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Courses = () => (
  <StaticQuery
    query={graphql`
      query CourseImages {
        course1: file(relativePath: { eq: "dl-course1.jpg" }) {
          childImageSharp {
            fixed(width: 220) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
        course2: file(relativePath: { eq: "dl-course2.jpg" }) {
          childImageSharp {
            fixed(width: 220) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
        course3: file(relativePath: { eq: "dl-course3.jpg" }) {
          childImageSharp {
            fixed(width: 220) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
        course4: file(relativePath: { eq: "dl-course4.jpg" }) {
          childImageSharp {
            fixed(width: 220) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
        course5: file(relativePath: { eq: "dl-course5.jpg" }) {
          childImageSharp {
            fixed(width: 220) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Img fixed={data.course1.childImageSharp.fixed} className="mr-2" />
        <Img fixed={data.course2.childImageSharp.fixed} className="mr-2" />
        <Img fixed={data.course3.childImageSharp.fixed} className="mr-2" />
        <Img fixed={data.course4.childImageSharp.fixed} className="mr-2" />
        <Img fixed={data.course5.childImageSharp.fixed} className="mr-2" />
      </>
    )}
  />
)

export default Courses
